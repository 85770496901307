export default {
  "announcements": {
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjusted opening times: Closed from November 13 through 15, November 16 and 17 limited menu."])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact details"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+31 595 - 434310"])}
  },
  "dishCategories": {
    "appetizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appetizers"])},
    "salads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salads"])},
    "pastas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastas"])},
    "lasagnas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasagnas"])},
    "kidsDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kids"])},
    "fishDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fish dishes"])},
    "meatDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meat dishes"])},
    "pizzas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizzas"])}
  },
  "dishDescriptions": {
    "appetizers": {
      "paneEBurro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bread with herb butter"])},
      "porzioneDiOlive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portion of olives"])},
      "vitelloTonato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finely sliced fricandeau with a homemade tuna mousse"])},
      "funghiAllaCrema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mushrooms sautéed in a cream sauce with garlic and red peppers"])},
      "prosciuttoCrudoEPane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parma ham and bread"])},
      "cozzeAllaMarinara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peeled mussels sautéed in herb butter with a spicy sauce"])},
      "cozzeAllaPescatora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peeled mussels in a spicy tomato sauce"])},
      "antipastoItaliano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian raw ham, salami, Italian cheese, olives, bread and herb butter"])}
    },
    "salads": {
      "insalataMista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed salad"])},
      "insalataTonno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed salad with tuna"])},
      "insalataGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed salad with gorgonzola"])},
      "insalataDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed salad with tuna and Parmesan cheese"])}
    },
    "pastas": {
      "bolognese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomato sauce with minced meat, onions, garlic and Parmesan cheese"])},
      "alPesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basil, pesto, garlic and Parmesan cheese"])},
      "carbonara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bacon, egg, onions, cream sauce and Parmesan cheese"])},
      "matriciana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spicy tomato sauce with bacon, onions, garlic and Parmesan cheese"])},
      "allaBurinata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mushrooms, ham, onions, cream, white wine sauce and Parmesan cheese"])},
      "aiBroccoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broccoli, gorgonzola sauce and Parmesan cheese"])},
      "vegetariana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various vegetables in a tomato cream sauce"])},
      "allaScoglio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomato sauce, mix of seafood, parsley and garlic"])},
      "alSalmone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomato cream sauce with pieces of smoked salmon"])},
      "dellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slightly spicy tomato cream sauce with bacon, ham, onions, bell pepper, mushrooms and garlic"])},
      "meta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choice of spaghetti, penne, fresh tagliatelle (+ ", _interpolate(_named("freshPastaPremium")), ") or fresh tortellini (+ ", _interpolate(_named("freshPastaPremium")), ")"])}
    },
    "lasagnas": {
      "tradizionale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With ham and cheese"])},
      "allaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With ham, cheese and gorgonzola sauce"])},
      "piccante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With ham, cheese, salami, sautéed mushrooms and red peppers"])},
      "dellaMamma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With ham, cheese, salami, broccoli, mozzarella and gorgonzola"])}
    },
    "kidsDishes": {
      "menuBambino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fries with chicken nuggets and mayonnaise"])},
      "penneBolognese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta with tomato sauce, ground beef, onion, garlic and Parmesan cheese"])},
      "pizzaBambino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizza with tomato sauce, cheese, ham or salami"])},
      "lasagnaTradizionale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small lasagna with ham and cheese"])}
    },
    "fishDishes": {
      "filettiDiSalmoneDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sautéed salmon steaks with shrimps in a slightly spicy cream sauce"])},
      "gamberoniAllaPavarotti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealed king prawns with smoked salmon, white wine sauce and cognac"])},
      "gamberoniAlVinoBianco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealed king prawns in white wine sauce, garlic and parsley"])},
      "gamberoniDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pealed king prawns, tuna, shrimps, mussels, tomato sauce, red peppers and garlic"])}
    },
    "meatDishes": {
      "scaloppinaAlVinoBianco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet in a white wine sauce"])},
      "scaloppinaAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet in a gorgonzola sauce"])},
      "scaloppinaAiFunghi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet with mushrooms and white wine cream sauce"])},
      "scaloppinaAllaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet with ham and cheese in a cream sauce"])},
      "scaloppinaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet with olives and capers in a tomato sauce"])},
      "saltimboccaAllaRomana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pork fillet prepared with raw ham and sage in a white wine sauce"])},
      "filettoDiPolloAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken fillet in a gorgonzola sauce"])},
      "filettoDiPolloAllaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken fillet with ham and cheese in a cream sauce"])},
      "filettoDiPolloAllaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken fillet with tomato sauce, capers, oregano and olives"])},
      "bisteccaAiFunghi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with mushrooms and white wine cream sauce"])},
      "bisteccaAzzurra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with onions and mushrooms in a cream sauce"])},
      "bisteccaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with a sauce of tomatoes, olives and capers"])},
      "bisteccaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with ham and cheese in a cream sauce"])},
      "bisteccaGorgonzolaENoce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with walnuts in a gorgonzola sauce"])},
      "bisteccaDelloChef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecôte with mushrooms, bell pepper, onions, bacon and ham in a slightly spicy tomato sauce"])},
      "filettoAlPepeVerde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beef flank with green pepper and cognac in a cream sauce"])},
      "filettoAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beef flank in a gorgonzola sauce"])}
    },
    "pizzas": {
      "composedByCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the customer's choice, price depends on composition"])},
      "foldedPizza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folded pizza with"])},
      "meta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Optionally with glutenfree bottom (", _interpolate(_named("glutenFreePremium")), ")"])}
    }
  },
  "gallery": {
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all photos"])}
  },
  "general": {
    "websiteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our website! Here you'll find our menu, contact details, and other general information."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "reservingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to dine with a group or just want to make sure there's a table available? You can contact us by phone for a reservation."])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "orderingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ordering for takeout or delivery is also possible by phone. We accept deliveries until ", _interpolate(_named("maxDeliveryTime")), ". Our delivery area consists of Uithuizen, Usquert, Zandeweer, Uithuizermeeden, Warffum, Roodeschool and Kantens."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "italianRestaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian restaurant"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socials"])}
  },
  "pizzaIngredients": {
    "anchovies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anchovies"])},
    "artichokes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artichokes"])},
    "arugula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arugula"])},
    "asparagus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asparagus"])},
    "bacon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bacon"])},
    "basil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basil"])},
    "bellPepper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bell pepper"])},
    "brie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brie"])},
    "broccoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broccoli"])},
    "capers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["capers"])},
    "cheese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cheese"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chicken"])},
    "eel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eel"])},
    "egg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["egg"])},
    "fourItalianCheeses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["four Italian cheeses"])},
    "freshTomatoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fresh tomatoes"])},
    "fricandeau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fricandeau"])},
    "garlic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garlic"])},
    "gorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gorgonzola"])},
    "ham": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ham"])},
    "italianSalami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian salami"])},
    "bologneseSauce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolognese sauce"])},
    "mixedSalad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mixed salad"])},
    "mozzarella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mozzarella"])},
    "mushrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mushrooms"])},
    "mussels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mussels"])},
    "olives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["olives"])},
    "onions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onions"])},
    "parmaHam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parma ham"])},
    "parmesanCheese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parmesan cheese"])},
    "pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pesto"])},
    "pineapple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pineapple"])},
    "porkFillet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pork fillet"])},
    "redPeppers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["red peppers"])},
    "roastBeef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roast beef"])},
    "salami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salami"])},
    "salmon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salmon"])},
    "shawarmaMeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shawarma meat"])},
    "shrimps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shrimps"])},
    "smokedSalmon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smoked salmon"])},
    "tomatoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomatoes"])},
    "tuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuna"])}
  },
  "openingTimes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening times"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened"])},
    "daysOpened": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("openingTimes.tuesday"), " - ", _linked("openingTimes.sunday")])},
    "daysClosed": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("openingTimes.monday")])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Excluding public holidays -"])}
  },
  "menu": {
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["- Updated on ", _interpolate(_named("lastUpdatedDate")), " -"])},
    "viewOurMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View our menu"])}
  },
  "pages": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  }
}