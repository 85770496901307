export default {
  "announcements": {
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangepaste openingstijden: Van 13 tot en met 15 november gesloten, 16 en 17 november beperkt menu."])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "contactDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locatie"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0595 - 434310"])}
  },
  "dishCategories": {
    "appetizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgerechten"])},
    "salads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salades"])},
    "pastas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta's"])},
    "lasagnas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasagnes"])},
    "kidsDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderen"])},
    "fishDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visgerechten"])},
    "meatDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vleesgerechten"])},
    "pizzas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizza's"])}
  },
  "dishDescriptions": {
    "appetizers": {
      "paneEBurro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brood met kruidenboter"])},
      "porzioneDiOlive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portie olijven"])},
      "vitelloTonato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijngesneden fricandeau met een huisgemaakte mousse van tonijn"])},
      "funghiAllaCrema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebakken champignons in roomsaus met knoflook en rode pepers"])},
      "prosciuttoCrudoEPane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parmaham en brood"])},
      "cozzeAllaMarinara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In kruidenboter gebakken gepelde mosselen met pikante saus"])},
      "cozzeAllaPescatora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepelde mosselen in pikante tomatensaus"])},
      "antipastoItaliano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiaanse rauwe ham, salami, Italiaanse kaas, olijven, brood en kruidenboter"])}
    },
    "salads": {
      "insalataMista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemengde salade"])},
      "insalataTonno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemengde salade met tonijn"])},
      "insalataGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemengde salade met gorgonzola"])},
      "insalataDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemengde salade met tonijn en parmezaanse kaas"])}
    },
    "pastas": {
      "bolognese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomatensaus met gehakt, uien, knoflook en parmezaanse kaas"])},
      "alPesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basilicum, pesto, knoflook en parmezaanse kaas"])},
      "carbonara": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spek, ei, uien, roomsaus en parmezaanse kaas"])},
      "matriciana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pikante tomatensaus met spek, uien, knoflook en parmezaanse kaas"])},
      "allaBurinata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champignons, ham, uien, room, witte wijnsaus en parmezaanse kaas"])},
      "aiBroccoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broccoli, gorgonzolasaus en parmezaanse kaas"])},
      "vegetariana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse groenten in een tomatenroomsaus"])},
      "allaScoglio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomatensaus, mix van zeevruchten, peterselie en knoflook"])},
      "alSalmone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomatenroomsaus met stukjes gerookte zalm"])},
      "dellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licht pikante tomatenroomsaus met spek, ham, uien, paprika, champignons en knoflook"])},
      "meta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keuze uit spaghetti, penne, verse tagliatelle (+ ", _interpolate(_named("freshPastaPremium")), ") of verse tortellini (+ ", _interpolate(_named("freshPastaPremium")), ")"])}
    },
    "lasagnas": {
      "tradizionale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ham en kaas"])},
      "allaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ham, kaas en gorgonzolasaus"])},
      "piccante": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ham, kaas, salami, gebakken champignons en rode pepers"])},
      "dellaMamma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ham, kaas, salami, broccoli, mozzarella en gorgonzola"])}
    },
    "kidsDishes": {
      "menuBambino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patat met kipnuggets en mayonaise"])},
      "penneBolognese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta met tomatensaus, gehakt, ui, knoflook en parmezaanse kaas"])},
      "pizzaBambino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pizza met tomatensaus, kaas, ham of salami"])},
      "lasagnaTradizionale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine lasagna met ham en kaas"])}
    },
    "fishDishes": {
      "filettiDiSalmoneDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebakken zalmmoten met garnalen in een licht pikante roomsaus"])},
      "gamberoniAllaPavarotti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepelde reuzengarnalen met gerookte zalm, witte wijnsaus en cognac"])},
      "gamberoniAlVinoBianco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepelde reuzengarnalen in witte wijnsaus, knoflook en peterselie"])},
      "gamberoniDellaCasa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepelde reuzengarnalen, tonijn, garnalen, mosselen, tomatensaus, rode pepers en knoflook"])}
    },
    "meatDishes": {
      "scaloppinaAlVinoBianco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet in witte wijnsaus"])},
      "scaloppinaAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet in gorgonzolasaus"])},
      "scaloppinaAiFunghi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet met champignons en witte wijn roomsaus"])},
      "scaloppinaAllaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet met ham en kaas in roomsaus"])},
      "scaloppinaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet met olijven en kappertjes in tomatensaus"])},
      "saltimboccaAllaRomana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varkensfilet bereid met rauwe ham en salie in een witte wijnsaus"])},
      "filettoDiPolloAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kipfilet in gorgonzolasaus"])},
      "filettoDiPolloAllaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kipfilet met ham en kaas in roomsaus"])},
      "filettoDiPolloAllaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kipfilet met tomatensaus, kappertjes, oregano en olijven"])},
      "bisteccaAiFunghi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met champignons en witte wijn roomsaus"])},
      "bisteccaAzzurra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met uien en champignons in roomsaus"])},
      "bisteccaPizzaiola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met saus van tomaten, olijven en kappertjes"])},
      "bisteccaValdostana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met ham en kaas in roomsaus"])},
      "bisteccaGorgonzolaENoce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met walnoten in gorgonzolasaus"])},
      "bisteccaDelloChef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrecote met champignons, paprika, uien, spek en ham in een licht pikante tomatensaus"])},
      "filettoAlPepeVerde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runderzijlende met groene peper en cognac in roomsaus"])},
      "filettoAllaGorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runderzijlende in gorgonzolasaus"])}
    },
    "pizzas": {
      "composedByCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar keuze van de klant, prijs afhankelijk van de samenstelling"])},
      "foldedPizza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dubbelgeslagen pizza met"])},
      "meta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Optioneel met glutenvrije bodem (", _interpolate(_named("glutenFreePremium")), ")"])}
    }
  },
  "gallery": {
    "viewAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk alle foto's"])}
  },
  "general": {
    "websiteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom op onze website! Hier vindt u ons menu, contactgegevens, en andere algemene informatie."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveren"])},
    "reservingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt u met een groep of wilt u gewoon zeker zijn van een tafel? Neem dan telefonisch contact met ons op voor een reservering."])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
    "orderingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bestellen voor afhalen of bezorgen kan bij ons ook telefonisch. Bezorgingen nemen wij tot maximaal ", _interpolate(_named("maxDeliveryTime")), " aan. Ons bezorggebied bestaat uit Uithuizen, Usquert, Zandeweer, Uithuizermeeden, Warffum, Roodeschool en Kantens."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
    "italianRestaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiaans restaurant"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socials"])}
  },
  "pizzaIngredients": {
    "anchovies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansjovis"])},
    "artichokes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["artisjokken"])},
    "arugula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rucola"])},
    "asparagus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asperges"])},
    "bacon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spek"])},
    "basil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basilicum"])},
    "bellPepper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paprika"])},
    "brie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brie"])},
    "broccoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["broccoli"])},
    "capers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kappertjes"])},
    "cheese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaas"])},
    "chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kip"])},
    "eel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paling"])},
    "egg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei"])},
    "fourItalianCheeses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vier Italiaanse kaassoorten"])},
    "freshTomatoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verste tomaten"])},
    "fricandeau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fricandeau"])},
    "garlic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["knoflook"])},
    "gorgonzola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gorgonzola"])},
    "ham": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ham"])},
    "italianSalami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiaanse salami"])},
    "bologneseSauce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bolognesesaus"])},
    "mixedSalad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gemengde salade"])},
    "mozzarella": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mozzarella"])},
    "mushrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["champignons"])},
    "mussels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mosselen"])},
    "olives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["olijven"])},
    "onions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uien"])},
    "parmaHam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parmaham"])},
    "parmesanCheese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parmezaanse kaas"])},
    "pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pesto"])},
    "pineapple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ananas"])},
    "porkFillet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["varkensfilet"])},
    "redPeppers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rode pepers"])},
    "roastBeef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rosbief"])},
    "salami": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salami"])},
    "salmon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zalm"])},
    "shawarmaMeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoarmavlees"])},
    "shrimps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garnalen"])},
    "smokedSalmon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gerookte zalm"])},
    "tomatoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomaten"])},
    "tuna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tonijn"])}
  },
  "openingTimes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openingstijden"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinsdag"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woensdag"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdag"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaterdag"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])},
    "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geopend"])},
    "daysOpened": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("openingTimes.tuesday"), " - ", _linked("openingTimes.sunday")])},
    "daysClosed": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("openingTimes.monday")])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesloten"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Uitgezonderd feestdagen -"])}
  },
  "menu": {
    "lastUpdated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["- Bijgewerkt op ", _interpolate(_named("lastUpdatedDate")), " -"])},
    "viewOurMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk ons menu"])}
  },
  "pages": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserveren"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerij"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  }
}